<template>
  <div class="pages p20">
    <div class="title-btn-block">
      <div class="title-block">{{ $t("message.my_evaluation") }}</div>
      <div>
         <router-link  :to="{ name: 'evaluation'}">
            <div class="my-btn mr-2 el-button" style="background:#00CC00; color:#fff; margin-right: 10px;">
                Создать
            </div>
        </router-link>
        <router-link :to="{ name: 'Home' }">
          <div class="my-btn cr1 el-button">
            <i class="el-icon-d-arrow-left"></i> {{$t('message.back')}}
          </div>
        </router-link>
      </div>
    </div>
    <div class="content-block">
      <div class="datetime-end bg p20 mb20">
        <el-divider content-position="left"> {{ $t("message.my_evaluation") }} </el-divider>
        <div class="etabl">
          <div class="scrol-table crmhome__scroll my-table">
            <table >
              <thead>
                <tr>
                  <th>{{ $t("message.whom_sent") }}</th>
                  <th>{{ $t("message.message_text") }}</th>
                  <th>{{ $t("message.evaluat") }}</th>
                  <th>{{ $t("message.sent_time") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="evaluation in lists" :key="evaluation.id">
                  <td>{{ evaluation.manager ? evaluation.manager.name +" "+ evaluation.manager.last_name : '' }}</td>
                  <td>{{ evaluation.comment }}</td>
                  <td> <star-rating :inline="true" :star-size="20" :read-only="true" :show-rating="false" :rating="evaluation.rating" :max-rating="10"></star-rating></td>
                  <td>{{ evaluation.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import StarRating from 'vue-star-rating'
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      complaints: [],
    };
  },
  components:{
    StarRating
  },
  mounted() {
    this.getTable();
  },
   computed:{
    ...mapGetters({
      lists:"evaluation/list",
    })
   },
  methods: {
    ...mapActions({
      updateList: "evaluation/index",
    }),

    getTable() {
      this.updateList(getItem("userId"))
        .then((res) => {
          this.complaints = res;
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>
